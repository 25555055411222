<template>
    <div class="trip__question">
        <v-expansion-panels accordion v-model="panel" multiple>
            <v-expansion-panel v-for="(item, index) in items" :key=" 'program-' + index">
                <v-expansion-panel-header>
                    <div style="max-width: 91%">{{ item.question }}</div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>{{ item.answer }}</v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    export default {
        name: 'TripAccordion',
        props: {
            items: { 
                type: Array, 
                default: () => []
            },
            openAllItems: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                panel: [0],
            }
        },
        async mounted() {
            this.isOpenItems()
        },
        methods: {
            isOpenItems() {
                if(this.openAllItems === true) {
                    for (let i=0; i<this.items.length; i++) {
                        this.panel.push(i)
                    }
                }
            },
        }
    }
</script>

<style lang="scss">
    .trip__question {
        display: flex;
        flex-direction: column;
        // border-bottom: 1px #b0b5c4 solid;
        padding-top: 10px ;
        
        &__program {
            padding: 10px 0;
        }
        &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #3A4256;
        }

        &__description {
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #3A4256;
        }

        .v-expansion-panels {
            border: none;
        }
        .v-expansion-panel {
            border: none;
            margin: 0;

            &::before {
                content: none;
            }
            &::after {
                margin-left: 26px;
            }
        }
        .v-expansion-panel-header {
            padding: 20px 0 20px 26px;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #3A4256;
            @media all and (max-width: 768px) {
                padding: 20px 0 20px 0;
            }
            &__icon{
                border: 1px #DA0A63 solid;
                border-radius: 50%;
                overflow: hidden;
                width: 26px;
                .v-icon {
                    color: #DA0A63 !important;
                }
            }
            &--active {
                color: #DA0A63;

                .v-expansion-panel-header__icon .v-icon {
                    background: #DA0A63;
                    color: #fff !important;
                }
            }
        }
        .v-expansion-panel-content {
            font-size: 14px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #3A4256;

            &__wrap {
                padding: 0 0 20px 26px;
                @media all and (max-width: 768px) {
                    padding: 0 0 20px 0;
                }
            }
        }
    }
</style>