<template>
    <div class="trip__advantages" v-if="translates && translates[langUrl]">
        <div v-if="!hideButtons" class="trip__advantages__buttons">
            <div class="trip__advantages__buttons__button" @click="all">{{ translates[langUrl].button_show[lang] }}</div>
            <div class="trip__advantages__buttons__button" @click="none">{{ translates[langUrl].button_hide[lang] }}</div>
        </div>
        <v-expansion-panels accordion v-model="panel" multiple>
            <v-expansion-panel v-for="(item, index) in items" :key=" 'program-' + index">
                <v-expansion-panel-header>{{ item.title }}</v-expansion-panel-header>
                <v-expansion-panel-content>{{ item.description }}</v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'TripAdvantages',
        props: {
            items: { 
                type: Array, 
                default: () => []
            },
            openAllItems: {
                type: Boolean,
                default: false
            },
            hideButtons: {
                type: Boolean,
                default: false
            },
        },
        data: () => ({
            panel: [],
            langUrl: 'tripAdvantages'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        async mounted() {
            this.isOpenItems()
        },
        methods: {
            isOpenItems() {
                if(this.openAllItems === true) {
                    for (let i=0; i<this.items.length; i++) {
                        this.panel.push(i)
                    }
                }
            },
            all () {
                this.panel = [...this.items.keys()].map((k, i) => i)
            },
            none () {
                this.panel = []
            },
        }
    }
</script>

<style lang="scss">
    .trip__advantages {
        display: flex;
        flex-direction: column;
        // border-bottom: 1px #b0b5c4 solid;
        padding-top: 10px ;
        &__buttons {
            display: flex;
            flex-direction: row;
            // align-items: flex-end;
            justify-content: flex-end;
            :first-child {
                margin-right: 20px;
            }
            &__button {
                font-weight: 600;
                font-size: 16px;
                line-height: 140%;
                letter-spacing: -0.02em;
                color: #D80765;
                text-decoration: underline;
                cursor: pointer;
            }
        }
        &__advantage {
            padding: 10px 0;
        }
        &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #3A4256;
        }

        &__description {
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #3A4256;
        }
        .v-expansion-panel {
            &:last-of-type {
            border-bottom: 1px solid #E5E6E9;
            margin: 0;
            }

            &::before {
                content: none;
            }
            &::after {
                margin-left: 26px;
            }
        }
        .v-expansion-panel-header {
            padding: 20px 0 20px 30px;
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: #3A4256;
            background: url(../../../assets/CheckMarkGreen.svg) 0 center no-repeat no-repeat;
            background-size: 22px;
            border: none;

            &--active {
                .v-expansion-panel-header__icon .v-icon {
                    color: #DA0A63 !important;
                }
            }
        }
        .v-expansion-panel-content {
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #3A4256;

            &__wrap {
                padding: 0 0 20px 26px;
            }
        }
        &.notIcluded {
            .v-expansion-panel-header {
                background: url(../../../assets/X-mark.svg) 0 center no-repeat no-repeat;
            }
        }
    }
</style>