<template>
    <div  class="gallery">
        <div class="gallery__container">
            <template v-for="(trip, index) in tripsFitered.slice(0,tripsShownCounter)">
                <template v-if="trip.photos && trip.photos.length">
                    <div :key="index" @click="openPopup(index)" class="gallery__container__item">
                            <div  class="gallery__container__picture" :style="`background-image: url(${ imageSrc(trip.photos[0].picture) })`"></div>
                            <div class="gallery__container__picture__overlay"></div>
                            <div class="gallery__container__text">
                                <div class="gallery__container__text__title"> {{ trip.name }} </div>
                                <div class="gallery__container__text__count">{{ trip.photos.length }}
                                    <img src="../../assets/Image-icon.svg" class="gallery__container__text__count__icon" />
                                </div>
                            </div>
                    </div>
                </template>
            </template>
        </div>
        <div class="gallery__button-container">
            <BaseButton v-if="tripsFitered.lenght > 4" class="gallery__button" @click="addTripShown" >Показать ещё поездки</BaseButton>
            <BaseButton v-if="tripsShownCounter > 4" class="gallery__button" @click="hideTripShown" >Скрыть поездки</BaseButton>
        </div>
        <v-dialog 
            v-model="shownPopup"
            max-width="1500px"
            scrollable
        >
        <div class="gallery__popup" v-if="trips && tripsFitered[activeTripIndex]">
            <div class="gallery__close" @click="closePopup"></div>
            <div class="gallery__popup__header">
                <div class="gallery__popup__header__title">{{ tripsFitered[activeTripIndex].name }}</div>
                <div class="gallery__popup__header__counter">{{ tripsFitered[activeTripIndex].photos.length }} фото <img src="../../assets/Image-icon.svg" /></div>
            </div>
            <div class="gallery__popup__image-container">
                <div class="gallery__popup__image-container__margin">
                <div 
                    v-for="(image, index) in tripsFitered[activeTripIndex].photos"
                    :key="index"
                    class="gallery__popup__image"
                    :style="`background-image: url(${ imageSrc(image.picture) })`"
                    @click="openPhoto(index)"
                ></div>
                </div>
                <v-dialog 
                    v-model="shownPhoto"
                >
                    <div class="gallery__popup__photo" 
                        :style="`background-image: url(${ imageSrc(tripsFitered[activeTripIndex].photos[activePhotoIndex].picture) })`">
                        <div class="gallery__close" @click="closePhoto"></div>
                    </div>
                </v-dialog>
            </div>
        </div>
        </v-dialog>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { imageSrc } from '@/helpers';
    import BaseButton from '../../components/common/BaseButton.vue';

    export default {
        name: 'Gallery',
        components: {
            BaseButton
        },
        props: {
            trips: {
                type: Array,
                default: () => []
            }
        },
        data: () => ({
            imageSrc,
            tripsShownCounter: 4,
            shownPopup: false,
            activeTripIndex: 0,
            shownPhoto: false,
            activePhotoIndex: 0,
        }),
        computed: {
            tripsFitered() {
                return this.trips.filter(trip => trip.photos.length > 0)
            },
        },
        methods: {
            addTripShown() {
                this.tripsShownCounter = this.tripsShownCounter + 2;
            },
            hideTripShown() {
                this.tripsShownCounter = 4;
            },
            openPopup(index) {
                this.activeTripIndex = index
                this.shownPopup = true
            },
            closePopup() {
                this.shownPopup = false
            },
            openPhoto(index) {
                this.activePhotoIndex = index
                this.shownPhoto = true
            },
            closePhoto() {
                this.shownPhoto = false
            },
        }
    }
</script>

<style lang="scss">
    .gallery {
        display: flex;
        flex-direction: column;
        &__container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: -15px;
            @media all and(max-width: 1280px) {
                max-width: 100%;
                flex-direction: column;
                align-items: center;
            }
            @media all and(max-width: 768px) {
                max-width: 100%;
                flex-direction: column;
            }
            &__item {
                margin: 15px;
            }
            &__picture {
                position: relative;
                width: 600px;
                height: 350px;
                background-position: center;
                background-size: cover;
                border-radius: 12px;
                overflow: hidden;
                @media all and(max-width: 768px) {
                    width: calc(100vw - 40px);
                    height: 270px;
                }
                // &__overlay {
                //     position: absolute;
                //     width: 100%;
                //     height: 100%;
                //     background: #000000;
                //     opacity: 0.5;
                //     cursor: pointer;
                    // display: none;
                    // &:hover {
                    //     display: visible;
                    // }
                // }
                
            }
            &__text {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 20px;
                &__title {
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 34px;
                    color: #273155;
                }
                &__count {
                    display: flex;
                    align-items: center;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 130%;
                    text-align: right;
                    color: #273155;
                    gap: 5px;
                    &__icon {

                    }
                }
            }
        }
        &__button-container {
            margin: 48px auto 0;
            display: flex;
            flex-direction: row;
            gap:20px;
        }
        &__button {
            width: 240px;
            height: 48px;
        }
        &__popup {
            position: relative;
            max-width: 1500px;
            height: max-content;
            background: #fff;
            display: flex;
            flex-direction: column;
            @media all and(max-width: 768px) {
                display: flex;
                flex-direction: column;
                overflow: scroll;
                height: 90%;
            }
            &__header {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                margin: 40px auto 30px;
                width: 1080px;
                &__title {
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 48px;
                    letter-spacing: -0.02em;
                    color: #273155;
                    margin-right: 15px;
                }
                &__counter {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 130%;
                    text-align: right;
                    color: #90949A;
                }
            }
            &__image-container {
                width: 1080px;
                // height: 100%;
                margin: 0 auto;
                @media all and(max-width: 768px) {
                    width: 100%;
                }
                &__margin {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin: -5px;
                    // grid-gap: 10px;
                    // grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
                    // grid-auto-flow: dense;
                    // overflow: wrap;
                    @media all and(max-width: 768px) {
                        flex-direction: column;
                        width: 100%;
                        // gap: 20px;
                        // overflow: scroll;
                        // height: 200px;
                    }
                }
            }
            &__image {
                background-position: center;
                background-size: cover;
                border-radius: 10px;
                // grid-row: span 1;
                // grid-column: span 1;
                width: 262.5px;
                height: 200px;
                margin: 5px;
                @media all and(max-width: 768px) {
                    min-width: 300px;
                    height: 200px;
                    // &:first-of-type {
                    //     margin-left: 20px;
                    // }
                    // &:last-of-type {
                    //     margin-right: 20px;
                    // }
                }
                // &:nth-of-type(1) {
                //     grid-row: span 3;
                //     grid-column: span 3;
                // }
                // &:nth-of-type(2) {
                //     grid-row: span 6;
                //     grid-column: span 3;
                // }
                // &:nth-of-type(3) {
                //     grid-row: span 6;
                //     grid-column: span 3;
                // }
                // &:nth-of-type(4) {
                //     grid-row: span 3;
                //     grid-column: span 3;
                // }
                // &:nth-of-type(5) {
                //     grid-row: span 3;
                //     grid-column: span 3;
                // }
                // &:nth-of-type(6) {
                //     grid-row: span 6;
                //     grid-column: span 3;
                // }
                // &:nth-of-type(7) {
                //     grid-row: span 6;
                //     grid-column: span 3;
                // }
                // &:nth-of-type(8) {
                //     grid-row: span 3;
                //     grid-column: span 3;
                // }
            }
            &__photo {
                position: relative;
                // width: 95vw;
                height: 90vh;
                background-position: center;
                background-size: contain;
            }
        }
        &__close {
            position: absolute;
            top: 25px;
            right: 25px;
            width: 30px;
            height: 30px;
            background: url(../../assets/PlusGrey.svg);
            background-size: contain;
            transform: rotate(45deg);
            cursor: pointer;
        }
    }
.v-dialog--active {
    display: contents;
}
</style>
