<template>
    <div class="yandexmap">
        <yandexMap
            
            class="yandexmap"
            :controls="['zoomControl']"
            :coords="centerCoordinates"
            zoom="7"
        >
            <template v-for="(direct, i) in directions">
                <ymapMarker 
                    :key="i"
                    :marker-id="i"
                    marker-type="Placemark"
                    :coords="direct.coordinates"
                />
            </template>
            <ymapMarker
                marker-id="1"
                marker-type="Polyline"
                :coords="polyline"
            />
        </yandexMap>
    </div>
</template>

<script>
    import { yandexMap, ymapMarker } from 'vue-yandex-maps';
    import { isMobile } from '@/helpers';

    export default {
        name: 'YandexMap',
        components: {
            yandexMap,
            ymapMarker
        },
        props: {
            directions: {
                type: Array,
                default: () => [],
            }
        },
        data: () => ({
            isDesktop: false,
        }),
        computed: {
            polyline() {
                return this.directions ? this.directions.map(item => item.coordinates) : [];
            },
            centerCoordinates() {
                return this.directions && this.directions.length ? [
                    this.directions[0] ? this.directions.reduce((sum, item) => {
                        return sum + +item.coordinates[0]
                    }, 0) / this.directions.length : 55.749451,
                    this.directions[0] ? this.directions.reduce((sum, item) => {
                        return sum + +item.coordinates[1]
                    }, 0) / this.directions.length : 37.542824,
                ] : [];
            }
        },
        methods:{
            onResize() {
                this.isDesktop = !isMobile();
            },
        }

    }
</script>
<style lang="scss">
.yandexmap {
        height: 100%;
        border-radius: 12px;
        overflow: hidden;
    }

</style>
