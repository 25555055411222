<template>
    <div class="ThumbnailsSlider">
        <div
            :style="`background-image: url(${ imageSrc(items[activeItem].hasOwnProperty('picture') ? items[activeItem].picture : items[activeItem]) })`"
            class="ThumbnailsSlider__picture">
        </div>
        <Splide
            v-if="items.length !== 0 && items[0].picture !== '' && items.length > 4 && isDesktop"
            id="thumbnails"
            class="ThumbnailsSlider__thumbnails"
            :options="{ rewind: true, fixedWidth : '145px', fixedHeight: '110px', perPage: 4, perMove: 1, gap: '10px' }">
            <template v-for="(item, index) in items"> 
                <SplideSlide :key=" 'picture-' + index" >
                    <div
                        v-if="item.picture"
                        @click="openPicture(index)"
                        :style="`background-image: url(${ imageSrc(item.hasOwnProperty('picture') ? item.picture : item) })`"
                        class="ThumbnailsSlider__thumbnail-picture">
                    </div>
                </SplideSlide>
            </template>
        </Splide>
        <div 
            v-if="items.length !== 0 && items[0].picture !== '' && items.length > 4 && !isDesktop"
            class="ThumbnailsSlider__thumbnails">
            <template v-for="(item, index) in items">
                <div 
                    :key=" 'picture-' + index"
                    @click="openPicture(index)"
                    :style="`background-image: url(${ imageSrc(item.hasOwnProperty('picture') ? item.picture : item) })`"
                    class="ThumbnailsSlider__thumbnail-picture">
                </div>
            </template>
        </div>
        <div 
            v-if="items.length !== 0 && items[0].picture !== '' && items.length <= 4 && items.length > 1"
            class="ThumbnailsSlider__thumbnails" style="display: flex; flex-direction: row; gap: 10px">
            <template v-for="(item, index) in items">
                <div 
                    :key=" 'picture-' + index"
                    @click="openPicture(index)"
                    :style="`background-image: url(${ imageSrc(item.hasOwnProperty('picture') ? item.picture : item) })`"
                    class="ThumbnailsSlider__thumbnail-picture">
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import { imageSrc, isMobile } from '@/helpers';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import '@splidejs/splide/dist/css/splide.min.css';

    export default {
        name: 'ThumbnailsSlider',
        components: {
            Splide,
            SplideSlide,
        },
        props: {
            items: {
                type: Array, 
                default: () => []
            },
            activeItem: {
                type: Number,
                default: 0
            }
        },
        data: () => ({
            imageSrc,
            isDesktop: false,
        }),
        mounted() {
            this.onResize()
        },
        methods: {
            openPicture(index) {
                this.activeItem = index
            },
            onResize() {
                this.isDesktop = !isMobile();
            },
        }
    }
</script>

<style lang="scss">
.ThumbnailsSlider {
    margin-left: 20px;
    width: 620px;
    display: flex;
    flex-direction: column;
    @media all and (min-width: 768px) {
        margin-left: 0;
    }
    @media all and (max-width: 768px) {
        width: 100%;
    }
    &__picture {
        position: relative;
        background-size: contain;
        background-position: center;
        border-radius: 8px;
        width: 620px;
        height: 350px;
        margin-right: 20px;
        margin: auto;
        transition: background 0.5s ease;
        @media all and (max-width: 768px) {
            width: 100%;
            height: 170px;
        }
    }
    &__thumbnail-picture {
        position: relative;
        background-size: cover;
        background-position: center;
        width: 145px;
        height: 110px;
        border-radius: 10px;
        cursor: pointer;
        @media all and (max-width: 768px) {
            min-width: 100px;
            height: 65px;
        }
    }
    &__thumbnails {
        margin-top: 20px;
        @media all and (max-width: 768px) {
            display: flex;
            flex-direction: row;
            gap: 8px;
            overflow: scroll;
            // scrollbar-width: 0;
        }
        
    }
    .splide__arrow {
        width: 40px;
        height: 40px;
        opacity: 1;
        padding: 12px;
        background: #fff;
        box-shadow: 0px 4px 22px rgba(44, 47, 56, 0.05);
        transform: matrix(1, 0, 0, -1, 0, 0);
        top: 35%;
        &:hover {
            background-color: #FFECF4;
        }
        @media all and (max-width: 768px) {
            display: none;
        }
    }
    .splide__arrow--prev {
        content: url(../../assets/BeforeArrow.svg);
        opacity: 1;
        left: -20px;
        
    }
    .splide__arrow--next {
        content: url(../../assets/NextArrow.svg);
        right: -20px;
    }
}
</style>