<template>
    <div class="date-select">
        <div class="date-select__text">{{ fieldTitle }}</div>
        <v-select
            v-bind="$attrs"
            ref="select"
            class="date-select__selecter"
            :value="value"
            append-icon="mdi-chevron-down"
            outlined
            v-on="$listeners"
        >
            <template #append>
                <img src="../../../assets/DownArrow.svg" class="date-select__selecter__append" />
            </template>
        </v-select>
    </div>
</template>

<script>
    export default {
        name: 'DateSelect',
        inheritAttrs: false,
        props: {
            value: {
                type: [String, Number, Array],
                default: ''
            },
            fieldTitle: {
                type: String,
                default: ''
            }
        }
    }
</script>

<style lang="scss">
.date-select {
    display: flex;
    flex-direction: row;
    padding-bottom: 16px;
    border-bottom: 1px solid #EBEBEB;
    align-items: center;
    position: relative;
    &__text {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.02em;
        color: #9FA5AD;
        margin-right: 10px;
    }
    .v-text-field--filled > .v-input__control > 
    .v-input__slot, .v-text-field--full-width > 
    .v-input__control > .v-input__slot, .v-text-field--outlined > 
    .v-input__control > .v-input__slot {
        min-height: 35px;
        max-height: 35px;
        width: 100%;
    }
    &__selecter {
        position: relative;
        
        .v-select__selection--comma {
            position: absolute;
            top: 8px;
            left: 0;
            margin: 0;
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.02em;
            color: #273155 !important;
        }
        
        .v-input__slot {
            border: 1px solid #EBEBEB;
            // border-radius: 10px;
            background: #EBEBEB !important;
            box-shadow: rgba(30, 28, 46, 0.03);
            margin-bottom: 0;
            min-height: none;

        }
        .v-input__icon--append > .v-icon {
            color: #E22319 !important;
        }
        &__append {
            margin:  -10px;
        }
        &.v-text-field--outlined.v-input--is-focused fieldset,
        &.v-text-field--outlined.v-input--has-state fieldset {
            border: 1px solid #000;
        }
        &.v-text-field--outlined.error--text.v-input--is-focused fieldset,
        &.v-text-field--outlined.error--text.v-input--has-state fieldset {
            border: 1px solid #ff5252 ;
        }
        &.v-text-field--outlined fieldset {
            border: none;
        }
        .v-text-field__details {
            min-height: 0;
            padding: 0 !important;
        }
        &.v-text-field.v-text-field--enclosed .v-text-field__details {
            margin-bottom: 0;
        }
        .v-messages.theme--light {
            min-height: 0;
        }
        &.v-text-field--outlined .v-label {
            margin-left: 8px;
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: -0.02em;
            @media all and (min-width: 768px) {
                display: none;
            }
        }
        
        &.v-text-field--outlined .v-label--active {
            top: 35px;
        }

        .v-messages.theme--light.error--text {
            margin-top: 4px;
        }
        fieldset {
            display:none
        }
    }
    &.no-text {
        border-bottom: none;
        width: 100%;
        .v-text-field--filled > .v-input__control > 
        .v-input__slot, .v-text-field--full-width > 
        .v-input__control > .v-input__slot, .v-text-field--outlined > 
        .v-input__control > .v-input__slot {
            width: 100%;
        }
        .date-select__text {
            display: none;
        }
    }
}
</style>
